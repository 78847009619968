<template>
  <list-container-widget
    :id="`people-${type.key}`"
    :title="type.key === 'noKey' ? widgetName : translatedType"
    :size="total"
    :appId="35"
    :type="type.key === 'noKey' ? null : type.key"
    stretch
  >
    <template #title="{ title }">
      {{ title }}
      <b-button variant="link" class="p-0 ml-1" :pressed.sync="isSearching">
        <feather-icon icon="SearchIcon" />
      </b-button>
    </template>
    <template #actions>
      <!-- Panel actions -->
      <ViewAllButton 
        item-type="people" 
        :type="type.key" 
      />
    </template>
    <people-horizontal-list
      :type="type"
      :searchable="isSearching"
      @total-updated="handleTotalUpdated"
      :update="newUpdate"
    />
  </list-container-widget>
</template>

<script>
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import ListContainerWidget from "@core/widgets/ListContainerWidget.vue";
import PeopleHorizontalList from "@/views/apps/people/components/PeopleHorizontalList.vue";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import LogoPlaceholder from "@/assets/images/placeholders/light/icono-general-light.png";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import placeholder from "@/assets/images/placeholders/light/people.svg";
import OrderBy from "@/@core/constants/OrderBy";
import { checkPermissions } from "@/@core/utils/roles-utils";

export default {
  name: "PeopleListWidget",
  components: {
    ListContainerWidget,
    PeopleHorizontalList,
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
  },
  mixins: [ToastNotificationsMixin, WidgetLayoutMixin],
  props: {
    type: {
      type: Object,
      required: true,
    },
    perPage: {
      type: Number,
      default: 8,
    },
  },
  data() {
    return {
      total: 100,
      isSearching: false,
      newUpdate: false,
      person: {
        name: "",
        typeKey: "",
        published: false,
        surname: "",
        email:'',
        jobTitle: {},
        organization: "",
        description: {},
      },
      noKey: null,
      imageURL: null,
      organizationLogoURL: null,
      logoSrc: "",
      avatarSrc: "",
      types: [],
      isSaving:false
    };
  },
  computed: {
    people() {
      if (this.$store.getters.peopleByType[this.type.key] != null) {
        return this.$store.getters.peopleByType[this.type.key].unpaginated;
      }
      return [];
    },
    translatedType() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.type.namePlural
      );
    },
    translatedTitle() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.type.name
      );
    },
    placeholder() {
      return placeholder;
    },
    logoPlaceholder() {
      return LogoPlaceholder;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canAdd() {
      return checkPermissions(
        "create",
        "success-story",
        this.loggedMemberRoles,
        this.collective
      );
    },
  },
  watch: {
    organizationLogoURL(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.logoSrc = value;
            })
            .catch(() => {
              this.logoSrc = null;
            });
        } else {
          this.logoSrc = null;
        }
      }
    },
    imageURL(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.avatarSrc = value;
            })
            .catch(() => {
              this.avatarSrc = null;
            });
        } else {
          this.avatarSrc = null;
        }
      }
    },
  },
  async created() {
    this.fetchTypes();
  },
  methods: {
    async handleTotalUpdated(total) {
      this.total = total;
      this.$emit("total-updated", this.total);
    },
    async fetchTypes() {

      const res = this.$store.getters.types?.AllPeopleTypes?.unpaginated || [];
      res.map((item) => {
        if(this.types.find((items) => items.key === item.key) == null){
                  this.types.push({
          name: typeof(item.name) === "object" ?
            item.name[this.locale] ||
            item.name.en ||
            item.name.es ||
            item.name.ca : item.name,
          key: item.key,
        });
        }
      });

      this.person.typeKey = this.type.key;
      this.noKey = this.types[0];
    },
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async loadPage() {
      this.isLoadingNextPage = true;
      await this.$store.dispatch("getItems", {
        itemType: "people",
        customName: "peopleByType",
        storedKey: this.type.key,
        page: 1,
        requestData: {
          typeKey: this.typeKey !== "noKey" ? this.typeKey : undefined,
          count: this.perPage,
          orderBySurname: OrderBy.ASC,
        },
      });
      this.isLoadingNextPage = false;
    },
  },
};
</script>
